.ant-pagination {
  justify-content: flex-start;
  padding: 0 0.875rem;
  margin: 0 0 5.5rem !important;
  font-weight: 400;

  .ant-pagination-options {
    order: -1;
    margin-right: auto;
    margin-left: 0;

    .ant-select-selector {
      border-radius: 1000px;
      border: none;
      padding: 0 1.25rem;
      background-color: $pagination-active-item-bg-color;
    }
  }
}

.ant-pagination-item-active {
  background-color: $pagination-active-item-bg-color;
  border: none;
  font-weight: 700;

  a {
    color: $text-color;
  }
}

.ant-pagination-item,
.ant-pagination-item-link {
  border: none !important;
  border-radius: 50%;
  line-height: 2.3;
}
