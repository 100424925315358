@import "../../../assets/scss/themes/base-theme";

.devicesSelect {
  :global {
    .ant-select-selection-search {
      height: 1rem !important;
    }

    .ant-select-selection-overflow {
      gap: 0.3125rem;
    }

    .ant-select-selector {
      padding: 0.5rem !important;
      max-height: 12.5rem;
      overflow: auto;
    }

    .ant-select-selection-item {
      margin: 0 !important;
    }
  }
}
