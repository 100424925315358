@import "../../../../assets/scss/themes/base-theme";

.headerTitle {
  &:global(.ant-typography) {
    margin-bottom: 0;
  }
}

.attachedFailureTag {
  margin-right: 40px;
}
