.ant-table {
  .ant-table-cell {
    font-weight: 600;
  }

  .ant-table-column-sorter {
    margin-left: 6px;
  }

  flex-grow: 1;
}
