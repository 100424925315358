.ant-typography strong {
  font-weight: 700;
}

h1.ant-typography,
h2.ant-typography {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 1.5rem;
}
