@import "../../../../assets/scss/themes/base-theme";

:global(.ant-menu-inline) {
  .footerMenuItem {
    :global {
      .ant-menu-title-content {
        display: inline-flex;
        flex-direction: row-reverse;
        align-items: center;

        .anticon {
          margin-left: auto;
        }
      }
    }
  }
}

.versionNumber {
  color: white;
  padding-left: 1.5rem;
  padding-right: 1rem;
  height: 2.5rem;
  line-height: 2.5rem;
}

.about {
  :global(.ant-list-item) {
    padding-left: 24px;
    display: flex;
    align-items: center;
    justify-content: initial;
    cursor: pointer;
  }

  :global(.ant-typography) {
    color: #fff;
    padding-left: 0.5rem;
  }

  .newTag {
    &:global(.ant-tag) {
      margin-left: 0.5rem;
      background-color: red;
      padding: 0.1rem 0.75rem;
      color: white;
      font-size: 0.625rem;
      border: none;
    }
  }
}
