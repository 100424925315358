html {
  .ant-form legend {
    border: none;
    color: $primary-color;
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 1.125rem;
    padding-top: 1rem;
  }

  input.ant-input-lg,
  .ant-input-affix-wrapper-lg,
  .ant-input-number-lg {
    height: 2.5rem;
  }

  .ant-input-affix-wrapper-lg {
    input.ant-input-lg {
      height: auto;
    }
  }

  .ant-input-number-input-wrap,
  .ant-input-number-lg input {
    height: 100%;
  }

  .ant-select-lg,
  .ant-input-lg {
    font-size: inherit;
  }

  .ant-form-item-extra {
    margin-top: 0.5rem;
  }

  .ant-input,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-picker,
  .ant-input-affix-wrapper-lg,
  .ant-input-number-lg {
    border-radius: 0.5rem;
  }

  .ant-input,
  .ant-radio-inner,
  .ant-select:not(.ant-select-customize-input):not(.ant-select-disabled)
    .ant-select-selector,
  .ant-input-affix-wrapper {
    border-color: transparent;
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    order: 1;
    margin-left: 0.25rem;
    margin-right: 0;
  }

  .ant-select-multiple.ant-select-lg .ant-select-selector {
    min-height: 2.5rem;
    padding: 0 0.75rem;

    &::after {
      margin-top: 0;
      margin-bottom: 0;
    }

    .ant-select-selection-item {
      margin-top: 0;
      margin-bottom: 0;
      background-color: $select-tag-bg-color;
      color: $select-tag-color;
      height: 1.625rem;
      line-height: 1.625rem;
      padding: 0 0.5rem;

      .ant-select-selection-item-content {
        margin-right: 0.375rem;
      }

      .ant-select-selection-item-remove {
        color: $select-tag-color;
      }
    }
  }

  .ant-picker-large {
    .ant-picker-input > input {
      height: 2.5rem;
      font-size: 0.8125rem;
    }
  }

  .ant-picker,
  .ant-input-number {
    width: 100%;
    border-color: transparent;
  }

  .ant-btn-icon-only.ant-btn-lg > * {
    font-size: 0.875rem;
  }

  .ant-input-group-addon .ant-input-search-button {
    border-color: transparent;
    pointer-events: none;
    background-color: $input-bg;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-input-search .ant-input {
    transition: none;

    + .ant-input-group-addon .ant-input-search-button {
      transition: none;
    }

    &:focus,
    &:hover {
      border-right-color: transparent;
      box-shadow: none;

      + .ant-input-group-addon .ant-input-search-button {
        border-color: $failure-assign-modal-search-addon-border-color;
      }
    }
  }

  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border-radius: 0 0.5rem 0.5rem 0;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-search {
    left: 0.6875rem;
    right: 0.6875rem;
  }

  .ant-select-loading {
    font-size: 0; // to hide prefilled value ID before options list is available
  }

  .ant-form h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }
}
