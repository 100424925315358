@import "../assets/scss/themes/base-theme";

.wrapper {
  &:global(.ant-modal .ant-modal-content .ant-modal-header) {
    border: none;
    padding-left: 3rem;
  }

  &:global(.ant-modal .ant-modal-content .ant-modal-footer) {
    border: none;
    padding-right: 3rem;
  }

  .brand {
    display: flex;
    justify-content: end;

    .brandImage {
      position: relative;
      left: 1rem;
      width: 9rem;
    }
  }
}

.companyImage {
  position: relative;
  top: 0.6rem;
  width: 9rem;
}

.headerTitle {
  &:global(.ant-typography) {
    margin-block: 1.5rem 0;
  }
}

.paragraph {
  &:global(.ant-typography) {
    margin: 0;
  }
}

.cardContent {
  &:global(.ant-card-bordered) {
    border: none;
  }

  &:global(.ant-card-bordered .ant-card-body) {
    padding-top: 0.5rem;
  }
}

.firstCard {
  &:global(.ant-card-bordered .ant-card-body) {
    padding-left: 0;
  }
}

.lastCard {
  &:global(.ant-card-bordered .ant-card-body) {
    display: flex;
    justify-content: end;
    padding-right: 0;
  }
}

.mvmiImage {
  width: 9rem;
}

.version {
  display: flex;
  align-items: center;

  :last-of-type {
    margin-bottom: 0;
  }

  &:last-of-type {
    display: flex;
    justify-content: end;
  }

  &:last-child {
    font-size: medium;
  }
}
