@import "../../../../assets/scss/themes/base-theme";

:global(.ant-avatar) {
  &.nearDeadlineAvatar {
    color: $warning-color;
    background-color: $warning-color-light-bg-color;
  }

  &.expiredDeadlineAvatar {
    color: $error-color;
    background-color: $error-color-light-bg-color;
  }

  &.nearDeadlineAvatar,
  &.expiredDeadlineAvatar {
    line-height: 1.875rem !important;
  }

  :global(.anticon) {
    &.icon {
      font-size: 18px;
      vertical-align: -0.32rem;
    }
  }
}

.label {
  color: $header-label-color;
  font-weight: bold;
  font-size: 0.75rem;
}

.labelWrapper {
  display: flex;
  flex-direction: column;
  line-height: 1;
}

.count {
  font-size: 1.125rem;
  font-weight: 800;
}

.logoWrapper {
  margin: 0 0 0.3125rem 2.5rem;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
