@import "../../assets/scss/themes/base-theme";

.space {
  width: 100%;
  gap: 0 !important;
}

.card {
  overflow: hidden !important;

  :global(.ant-card-body) {
    height: 100%;
  }
}

.maxHeight {
  height: 100%;

  :global {
    .ant-table-wrapper,
    .ant-spin-nested-loading,
    .ant-spin-container {
      height: 100%;
    }

    .ant-spin-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
