@import "../../assets/scss/themes/base-theme";

.footer {
  padding: 1.75rem 0;
  border-top: 1px solid $register-card-footer-border-color;
  text-align: center;
  margin-top: 2rem;
}

.space {
  gap: 2rem !important;
  width: 100%;
}

.logo {
  max-height: 3.75rem;
}

.appName {
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  margin-bottom: 0.8rem;
  text-transform: uppercase;
}

.logoAndAppNameContainer {
  display: flex !important;
  justify-content: center;
}
