@import "../../../../assets/scss/themes/base-theme";

.pageHeader {
  &:global(.ant-page-header) {
    padding: 0.5rem 0;
  }

  :global {
    .ant-page-header-heading-title,
    .ant-page-header-back-button {
      color: $failure-view-repairman-heading-page-title-color;
    }

    .ant-page-header-heading-title {
      font-weight: 700;
      font-size: 1.625rem;
      line-height: 1.875rem;
    }

    .ant-page-header-back-button {
      font-size: 1.875rem;
    }
  }
}

.selectWrapper {
  min-width: 12.5rem;

  :global {
    .ant-select {
      width: 100%;
    }

    .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
      .ant-select-selector {
      height: 3.5625rem;
      border-radius: 6.25rem;
    }

    .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-item {
      line-height: 3.5625rem;
      font-weight: 800;
      font-size: 20px;
    }

    .ant-select-arrow {
      color: $text-color;
    }
  }
}

.selectDropdown {
  :global {
    .ant-select-item {
      font-size: 1.25rem;
      line-height: 2.5;
    }

    .rc-virtual-list-scrollbar.rc-virtual-list-scrollbar-show {
      display: block !important;
    }
  }
}

.tabs {
  :global {
    .ant-tabs-tab-btn {
      font-weight: 700;
      font-size: 1.375rem;
      line-height: 1.375rem;
    }
  }

  &:global(.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar) {
    height: 0.25rem;
  }
}
