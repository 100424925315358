@import "../../assets/scss/themes/base-theme";

.titleContainer {
  border: none;
  padding: 16px 32px 0;
  justify-content: flex-end !important;
}

.title {
  font-size: 24px !important;
}

.card {
  border: none !important;
}

.fieldsetWrapper {
  width: 100%;
}

.fieldsetFieldsWrapper {
  height: 100%;
  margin-bottom: 16px;
  padding-bottom: 16px;
}

.field {
  :global {
    .ant-form-item-control-input-content {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .ant-form-item-control {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }

    .ant-form-item-extra {
      color: $color-gray-1;
      margin-top: 0;
    }
  }
}

.infoIcon {
  margin-top: 5px;
}

:export {
  primaryColor: $primary-color;
  errorColor: $error-color;
}
