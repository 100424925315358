@import "../../assets/scss/themes/base-theme";

.extraMargin {
  margin-top: 24px;
}

.tabs {
  :global {
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-weight: 600;
    }
    .ant-tabs,
    .ant-tabs-content {
      height: 100%;
    }
  }

  height: 100%;
}
