@import "../../../../assets/scss/themes/base-theme";

@import "../config";

.footerMenuGroup {
  margin-top: auto;
}

.sider {
  position: relative;

  :global(.ant-layout-sider-children) {
    display: flex;
    flex-direction: column;

    /*
     If sidebar needs to be scrollable, uncomment BOTH lines. Overflowing figure will disappear.
    */
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.logoWrapper {
  margin: $sh-layout-logo-wrapper-margin;
  text-align: center;
}

.appName {
  color: $app-name-color;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
}
